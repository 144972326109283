import React from 'react';
import Modal from 'react-modal';
import "./thank-you-modal.scss";
import { FaTimes } from "react-icons/fa";


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default function ThankYouModal({ open, onClose }) {

    const handleKeyDown = (event) => {
        // Check if the key pressed is "Enter" or "Space"
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          onClose();
        }
      }

  return (
    <div>
      <Modal
        isOpen={open}
        onRequestClose={onClose}
        style={customStyles}
        contentLabel="Thank you Modal"
      >
        <div className="thank-you-modal">
          <span className='cross-icon' onClick={onClose}  onKeyDown={handleKeyDown} 
            role="button" 
            tabIndex={0}
            aria-label="Close modal"><FaTimes /></span>
          <h1 className='font-40'>Thank you</h1>
          <p>Now that you’ve shared the best way to contact you, a representative will reach out shortly to hear your story and talk about next steps.</p>
          <div className="btn-wrap">
            <button className='btn btn-primary' onClick={onClose}>close</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
